import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  GET_CUSTOMER,
  GET_CUSTOMERS,
  DELETE_CUSTOMERS,
  DELETE_CUSTOMERS_BY_FILTER,
  UPDATE_CUSTOMER,
  GET_CUSTOMER_CASES,
  VALIDATE_DEPENDENCIES,
  CALCULATE_INSTALMENTS,
  GET_CUSTOMER_INFO,
  GET_CUSTOMER_BASIC_INFO,
  UPDATE_CUSTOMER_BASIC_INFO,
  UPDATE_CUSTOMER_LOCATION_INFO,
  GET_CUSTOMER_PAYMENTS,
  GET_OPERATIONS_BY_CUSTOMER,
  GET_CUSTOMER_ACTIVITY,
  SEND_EMAIL_USAGE,
  SEND_SMS_USAGE,
  GET_CUSTOMER_KPIS,
} from './customers.GraphQL';
import {
  CustomerCase,
  CustomerCriteriaModel,
  SearchInCustomerListResult,
} from '@models/customer.model';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  firstValueFrom,
  of,
  throwError,
  mergeMap,
  forkJoin,
} from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CurrentUserService } from '@core/services/currentUser.service';
import { PaymentAgreementService } from '@modules/payment-agreement/services/payment-agreement.service';
import { PermissionType } from '@admin/modules/users/models/user.model';

export const GET_TENANT = gql`
  query ($id: ID!) {
    tenant(id: $id) {
      id
      name
      timezone
      language
      logoUrl
      operationType
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private currentUser: CurrentUserService,
    private paymentAgreementService: PaymentAgreementService,
  ) {}

  getTenant(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: GET_TENANT,
          variables: {
            id: id,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data.tenant);
          }
        });
    });
  }

  getCustomer(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: GET_CUSTOMER,
          variables: {
            id: id,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data.customer);
          }
        });
    });
  }

  getCustomerInfo(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: GET_CUSTOMER_INFO,
          variables: {
            id,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data.customer);
          }
        });
    });
  }

  getCustomerBasicInfo(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: GET_CUSTOMER_BASIC_INFO,
          variables: {
            id,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data.customer);
          }
        });
    });
  }

  updateCustomerBasicInfo(customerId: string, input: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: UPDATE_CUSTOMER_BASIC_INFO,
          variables: {
            id: customerId,
            input: input,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data);
          }
        });
    });
  }

  getCustomerLocationInfo(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: GET_CUSTOMER_BASIC_INFO,
          variables: {
            id,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data.customer);
          }
        });
    });
  }

  updateCustomerLocationInfo(customerId: string, input: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: UPDATE_CUSTOMER_LOCATION_INFO,
          variables: {
            id: customerId,
            input: input,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data);
          }
        });
    });
  }

  getCustomerCases(tenantId: string, customerId: string): Observable<any> {
    return this.apollo
      .query({
        query: GET_CUSTOMER_CASES,
        variables: {
          tenantId: tenantId,
          customerId: customerId,
        },
      })
      .pipe(
        mergeMap((res: any) => {
          if (res.errors?.length) {
            throw new Error(res.errors[0].message);
          } else {
            const customerCases = res.data.customerCases.map((customerCase: any) => ({
              ...customerCase,
              validation: this.paymentAgreementService.checkvalidateDependencies(customerCase.id),
            }));

            const validationObservables = customerCases.map((customerCase: any) =>
              this.paymentAgreementService.checkvalidateDependencies(customerCase.id).pipe(
                map((validation: boolean) => ({
                  ...customerCase,
                  validation: validation,
                })),
              ),
            );

            return forkJoin(validationObservables);
          }
        }),
      );
  }

  getCustomerOperations(tenantId: string, customerId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: GET_OPERATIONS_BY_CUSTOMER,
          variables: {
            criteria: {
              tenantId: tenantId,
              customerId: customerId,
            },
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res.errors[0].message);
          } else {
            resolve(res.data.customerOperations);
          }
        });
    });
  }

  getCustomerPayments(tenantId: string, customerId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: GET_CUSTOMER_PAYMENTS,
          variables: {
            criteria: {
              tenantId: tenantId,
              customerId: customerId,
            },
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res.errors[0].message);
          } else {
            resolve(res.data.customerPayments);
          }
        });
    });
  }

  getCustomerList(criteria: any): Observable<any> {
    return this.apollo
      .query({
        query: GET_CUSTOMERS,
        variables: {
          criteria: {
            tenantId: criteria.filters.tenantId,
            pagination: criteria.pagination,
            filters: this.transformToFilter(criteria),
          },
        },
      })
      .pipe(
        map((res: any) => {
          if (res.errors?.length) {
            throw res;
          }
          return <SearchInCustomerListResult>res.data.searchInCustomerList;
        }),
        catchError((err) => {
          console.error(err);
          return throwError(err);
        }),
      );
  }

  public transformToFilter(criteria: CustomerCriteriaModel): any {
    return {
      searchPattern: criteria.filters.searchPattern,
      externalId: criteria.filters.externalId,
      nif: criteria.filters.nif,
      collectorIds: criteria.filters.collectorIds,
    };
  }

  restartCustomerList(tenantId: string): Promise<SearchInCustomerListResult> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: GET_CUSTOMERS,
          variables: {
            criteria: {
              tenantId,
              pagination: { offset: 0, limit: 10 },
              filters: {
                searchPattern: '',
                externalId: '',
                collectorIds: this.currentUser.currentUser.collectorIds,
                nif: '',
              },
            },
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data.searchInCustomerList);
          }
        });
    });
  }

  deleteCustomers(customerIds: Array<string>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: DELETE_CUSTOMERS,
          variables: {
            ids: customerIds,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data);
          }
        });
    });
  }

  deleteCustomersByFilter(filters: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: DELETE_CUSTOMERS_BY_FILTER,
          variables: {
            input: { filters },
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data);
          }
        });
    });
  }

  updateCustomer(customerId: string, input: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: UPDATE_CUSTOMER,
          variables: {
            id: customerId,
            input: input,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data);
          }
        });
    });
  }

  checkvalidateDependencies(_case: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: VALIDATE_DEPENDENCIES,
          variables: {
            customerCaseId: _case.id,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            const { validatePaymentAgreementsDependencies: v } = res.data;
            _case.validation = v;
            resolve(_case);
          }
        });
    });
  }

  calculatePaymentAgreementInstalments(
    customerCaseId: string,
    numberOfInstalments: number,
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: CALCULATE_INSTALMENTS,
          variables: {
            customerCaseId,
            numberOfInstalments,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data);
          }
        });
    });
  }

  async setListValidation(cases: CustomerCase[]) {
    console.log('VALIDATIONss', cases);
    const promises = cases.map((_case) => {
      return this.checkvalidateDependencies(_case);
    });
    const res = await Promise.all(promises);
    return res;
  }

  async getLatLongFromAddress(address: string) {
    try {
      const location = await firstValueFrom(
        this.http
          .get(
            `https://www.mapquestapi.com/geocoding/v1/address?key=${environment.mapQuest}&location=${address}`,
          )
          .pipe(map((response: any) => response.results[0].locations[0].latLng)),
      );

      return [location.lng, location.lat];
    } catch (error) {
      return [0, 0];
    }
  }

  getCustomerActivity(customerId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: GET_CUSTOMER_ACTIVITY,
          variables: {
            criteria: {
              filters: {
                customerId,
              },
            },
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res.errors[0].message);
          } else {
            resolve(res.data.searchCustomerInteractions);
          }
        });
    });
  }

  //NOTIFICATIONS
  private sendNotification(mutationType: any, customerCaseId: string, usage: string) {
    return this.apollo
      .mutate({
        mutation: mutationType,
        variables: {
          input: {
            customerCaseId,
            usage,
          },
        },
      })
      .pipe(
        switchMap((res) => {
          if (res.errors?.length) {
            return of({ error: res.errors });
          }
          return of(res.data);
        }),
      );
  }

  sendEmailPaylinkNotificationUsage(customerCaseId: string, usage: string) {
    return this.sendNotification(SEND_EMAIL_USAGE, customerCaseId, usage);
  }

  sendSmsPaylinkNotificationUsage(customerCaseId: string, usage: string) {
    return this.sendNotification(SEND_SMS_USAGE, customerCaseId, usage);
  }

  getCustomerKpis(customerId: string) {
    return new Promise<any>((resolve, reject) => {
      return this.apollo
        .query({
          query: GET_CUSTOMER_KPIS,
          variables: {
            customerId: customerId,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data);
          }
        });
    });
  }

  // UTILS ACTIVITIES
  getColor(category: string, eventName: string): string {
    const colorMap: Record<string, Record<string, string>> = {
      payment_order: {
        payment_order_created: 'rgb(238,242,255)',
        payment_order_canceled: 'rgb(254,242,242)',
        payment_order_captured: 'rgb(240,253,244)',
      },
      paylink_notification: {
        paylink_notification_error: 'rgba(240, 60, 60, 0.35)',
        paylink_notification_visited: 'rgb(255,247,237)',
        paylink_notification_login_visited: 'rgba(252, 178, 23, 0.35)',
        paylink_notification_login_failed: 'rgba(240, 60, 60, 0.35)',
        paylink_notification_sent: 'rgb(243,251,253)',
        paylink_notification_received: 'rgb(254,246,250)',
        paylink_notification_read: 'rgba(111, 207, 151, 0.7)',
      },
      feedback: {
        feedback_sent: 'rgb(255,247,237)',
        feedback_visited: 'rgb(255,247,237)',
      },
    };
    return colorMap[category]?.[eventName] ?? 'rgba(161, 161, 161, 0.7)';
  }

  getIcon(category: string, eventName: string): string {
    const iconMapping: any = {
      payment_order: {
        payment_order_created: 'pi pi-bolt',
        payment_order_canceled: 'pi pi-times',
        payment_order_captured: 'pi pi-dollar',
      },
      paylink_notification: {
        paylink_notification_error: 'pi pi-times',
        paylink_notification_visited: 'pi pi-eye',
        paylink_notification_login_visited: 'pi pi-sign-in',
        paylink_notification_login_failed: 'pi pi-sign-out',
        paylink_notification_received: 'pi pi-inbox',
        default: 'pi pi-send',
      },
      feedback: {
        feedback_sent: 'pi pi-comment',
        feedback_visited: 'pi pi-eye',
      },
    };

    if (!iconMapping[category]) {
      return '';
    }

    return iconMapping[category][eventName] || iconMapping[category].default || '';
  }

  getName(eventName: string): string {
    const eventTranslations: Record<string, string> = {
      payment_order_created: 'ha iniciado un intento de pago',
      payment_order_canceled: 'ha cancelado el intento de pago',
      payment_order_captured: 'ha pagado',
      paylink_notification_error: 'Error notificación',
      paylink_notification_visited: 'ha visitado ',
      paylink_notification_login_visited: 'Login',
      paylink_notification_login_failed: 'Login error',
      paylink_notification_sent: 'ha enviado notificación desde',
      paylink_notification_received: 'ha recibido notificación desde',
      paylink_notification_read: 'Notificación leída',
      feedback_sent: 'ha alegado',
      feedback_visited: 'ha visitado ',
      api: 'API',
      campaign: 'campaña',
      payment_agreement: 'acuerdo de pago',
      reminder: 'enlace de pago', //QA: change by reminder (?)
    };

    return eventTranslations[eventName] ?? eventName;
  }

  getIconColor(category: string, eventName: string): string {
    const colorMap: Record<string, Record<string, string>> = {
      payment_order: {
        payment_order_created: 'rgb(99,102,241)',
        payment_order_canceled: 'rgb(185,28,28)',
        payment_order_captured: 'rgb(34,197,94)',
      },
      paylink_notification: {
        paylink_notification_error: 'rgba(255, 0, 0, 1)',
        paylink_notification_visited: 'rgb(249,115,22)',
        paylink_notification_login_visited: 'rgba(252, 178, 23, 0.95)',
        paylink_notification_login_failed: 'rgba(255, 0, 0, 1)',
        paylink_notification_sent: 'rgb(4,127,148)',
        paylink_notification_received: 'rgb(165,50,107)',
        paylink_notification_read: 'rgba(66, 173, 100, 1)',
      },
      feedback: {
        feedback_sent: 'rgb(249,115,22)',
        feedback_visited: 'rgb(249,115,22)',
      },
    };
    return colorMap[category]?.[eventName] ?? 'var(--primary-color)';
  }

  canWrite() {
    return this.currentUser.allowAccessPermission([
      PermissionType.FULL_ACCESS,
      PermissionType.CUSTOMER_WRITE,
    ]);
  }

  canRead() {
    return this.currentUser.allowAccessPermission([
      PermissionType.FULL_ACCESS,
      PermissionType.CUSTOMER_READ,
    ]);
  }
}
