import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import * as joint from 'jointjs';
import svgPanZoom from 'svg-pan-zoom';
import { NodeModel } from './model/node.model';
import { DEFAULT_NODES, PAPER_CONFIG, ZOOM_CONFIG } from './config/constants';

import { CreateNewNodesService } from './services/create-new-nodes.service';
import { MonitoringTooltip } from './services/create-monitoring-template.service';

import * as helper from './helpers';
import {
  InvalidNodeTypeError,
  NodeNotFoundError,
} from '@modules/campaigns/Domain/Errors/WorkflowErrors';
import { ToastService } from '@services/ui';
import { NodeFactory } from '@modules/campaigns/Domain/Factories/NodeFactory';
import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { WorkflowClass } from '@modules/campaigns/Domain/WorkflowClass';
import { hascopyIds } from '@modules/campaigns/Domain/Factories/HasNodeConfig';
import { ConditionalConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { WorkflowType } from '@modules/campaigns/Domain/Enums/WorkflowType';
import { CopyModel } from '@models/copies/copies.model';

@Component({
  selector: 'app-joint-js',
  templateUrl: './joint-js.component.html',
  styleUrls: ['./joint-js.component.scss'],
})
export class JointJsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() nodesReady = new EventEmitter<any>();
  @Input() isEditable = true;
  @Input() isMonitoring = false;
  @Input() workflow: any | undefined;
  @Input() isNodeSelector = true;
  @Input() isNodeParameters = true;
  @Input() nodes?: NodeModel[];
  @Input() idGraph: string = 'myJointPaper';

  //DOMAIN
  public selectedNode: NodeClass | undefined;
  //END DOMAIN

  public graph?: joint.dia.Graph;
  public paper?: joint.dia.Paper;
  public panZoom?: any;
  public destroyingComponent = false;
  isSelectionGoalMode = false;
  selectedGoalNode: NodeClass | undefined;
  isOnlyCanvas = false;
  copiesList: CopyModel[] = [] as any[];

  public selectedElement?: joint.dia.Element;
  private lastSelectedElement?: joint.dia.Element;

  private createNewNodes = inject(CreateNewNodesService);
  private monitoringTooltip = inject(MonitoringTooltip);
  private cdr = inject(ChangeDetectorRef);

  constructor(
    private toastService: ToastService,
    private config: DynamicDialogConfig,
  ) {}
  NodeValidator = new helper.NodesValidator();

  ngOnInit() {
    try {
      if (this.config.data && this.config.data.nodes) {
        this.nodes = this.config.data.nodes;
        this.isMonitoring = true;
        this.isEditable = false;
        this.workflow = new WorkflowClass('show-id', 'Only Show Workflow', WorkflowType.DRIP);
        this.isOnlyCanvas = true;
      }
      this.graph = new joint.dia.Graph();
      this.initWorkflow();
      this.draw();
    } catch (error) {
      if (error instanceof NodeNotFoundError || error instanceof InvalidNodeTypeError) {
        this.toastService.showError('Error al iniciar workflow', error.message);
        console.error(error.message);
      } else {
        console.error(error, 'Unexpected error on init');
      }
    }
  }

  ngAfterViewInit() {
    this.setPaper();
    this.svgPanZoom();
    if (this.isEditable) {
      this.paperActions();
    }
  }

  initWorkflow() {
    //DOMAIN
    if (this.workflow.nodes.length !== 0) {
      this.nodes = this.workflow.nodes;
    } else {
      if (!this.nodes || this.nodes?.length === 0) {
        this.nodes = DEFAULT_NODES();
      }
      this.nodes.forEach((node) => {
        let newDNode = NodeFactory.parseAndCreateNode(node);
        if (newDNode) {
          this.workflow.addNode(newDNode);
        } else {
          throw new Error('Could not parse info');
        }
      });
      this.workflow.assignParentNodes();
      //END DOMAIN
    }

    (this.workflow as WorkflowClass).orderNodes();
  }

  getGraph() {
    return {
      graph: this.graph,
      paper: this.paper,
      selectedElement: this.selectedElement,
    };
  }

  draw() {
    let elements: joint.dia.Element[] = [];

    // Crear nodos
    elements = this.createNodes(elements);

    // Connect Links between nodes
    this.linkNodes(elements);

    this.updateNodes();
  }

  createNodes(
    elements: joint.dia.Element<joint.dia.Element.Attributes, joint.dia.ModelSetOptions>[],
  ) {
    (this.workflow as WorkflowClass).nodes!.forEach((node, i) => {
      //Change shape of node depending on copyids
      // node = helper.hackSuperNodes(node);

      const element = this.createNewNodes.createNode(node.type, node.position.x, node.position.y, {
        ...node.config,
        childrens: node.childrenNodes,
        original: node.id,
        parent: node.parentNodes,
      })!;

      elements.push(element);
      this.graph!.addCell(element);
      if (!this.isEditable && this.isMonitoring) {
        this.setTooltip(node, node.position);
      }
    });

    return elements;
  }

  linkNodes(
    elements: joint.dia.Element<joint.dia.Element.Attributes, joint.dia.ModelSetOptions>[],
  ) {
    (this.workflow as WorkflowClass).nodes.forEach((node) => {
      const sourceElement = elements.find((el) => el.attributes['data'].original === node.id);
      if (
        sourceElement &&
        (node.type === NodeType.PaylinkNotificationStatus ||
          node.type === NodeType.ConditionalSegments)
      ) {
        const trueTargetElement = elements.find(
          (el) => el.attributes['data'].original === node.config?.['cases']?.true,
        );
        const falseTargetElement = elements.find(
          (el) => el.attributes['data'].original === node.config?.['cases']?.false,
        );

        if (trueTargetElement) {
          const trueLink = this.createNewNodes.createLink(
            sourceElement,
            trueTargetElement,
            'right',
            'top',
          );
          this.graph!.addCell(trueLink);
        }

        if (falseTargetElement) {
          const falseLink = this.createNewNodes.createLink(
            sourceElement,
            falseTargetElement,
            'bottom',
            'top',
          );
          this.graph!.addCell(falseLink);
        }
      } else if (sourceElement) {
        node.childrenNodes.forEach((childId) => {
          const targetElement = elements.find((el) => el.attributes['data'].original === childId);

          try {
            if (targetElement) {
              //DOMAIN
              let actualDNode = this.workflow.findNode(node.id);
              if (actualDNode?.childrenNodes.length == 1) {
                let targetDNode = this.workflow.findNode(actualDNode?.childrenNodes[0]);
                let targetDID = targetDNode?.id;
                let actualDID = actualDNode?.id;
                const link = this.createNewNodes.createLink(
                  sourceElement,
                  targetElement,
                  this.checkparent(sourceElement.id, this.graph!.getLinks()) ? 'right' : 'bottom',
                  'top',
                  { actualDID, targetDID },
                );
                //END DOMAIN
                this.graph!.addCell(link);
              } else {
                console.log('Has more than one child');
              }
            }
          } catch (error) {
            if (error instanceof NodeNotFoundError || error instanceof InvalidNodeTypeError) {
              this.toastService.showError('Error al linkear elementos del workflow', error.message);
              console.error(error.message);
            } else {
              this.toastService.showError(
                'Error al linkear elementos del workflow',
                (error as Error).message,
              );
              console.error(error, 'Unexpected error on linking nodes');
            }
          }
        });
      }
    });
  }

  checkparent(parent: any, links: any): boolean {
    let ids = [];
    if (links.length > 0) {
      ids = links.map((l: any) => l.attributes.source.id);
      return ids.some((id: any) => id === parent);
    }
    return false;
  }

  setTooltip(node: any, position: { x: number; y: number }) {
    const extractedExtras = this.nodes?.map((node) => node.parameters.extra);
    let extractedNodeParams = undefined;
    if (extractedExtras)
      extractedNodeParams = extractedExtras.find((extra) => extra.id === node.id);

    this.monitoringTooltip.createMonitoringTemplate(node, extractedNodeParams).subscribe({
      next: (nodeText) => {
        const tooltip = new joint.shapes.standard.Rectangle({
          position: { x: position.x + 120, y: position.y + 35 },
          attrs: {
            body: {
              fill: 'black',
              stroke: 'none',
              strokeWidth: 5,
            },
            label: {
              text: nodeText,
              textAnchor: 'start',
            },
          },
        });
        this.graph!.addCell(tooltip);
      },
      error: (err) => {
        this.toastService.showError('Error', 'Failed to load tooltip text');
        console.error('Failed to load tooltip text', err);
      },
    });
  }

  setDraggedNode(node: any) {
    this.isSelectionGoalMode = false;
    this.removeHighlight();
    this.resetLastSelectedElementStyle();
    this.selectedElement = node;

    //DOMAIN
    this.selectedNode = this.workflow.findNode(node.attributes['data'].original);
    //END DOMAIN

    this.checkNode(this.selectedElement, this.selectedNode!);
    this.applySelectedElementStyle();
    this.lastSelectedElement = this.selectedElement;
    this.nodes?.push(node);
    this.setNewNodes();
  }

  resetLastSelectedElementStyle() {
    //DOMAIN
    this.selectedNode = undefined;
    //DOMAIN

    if (this.lastSelectedElement) {
      this.lastSelectedElement.attr({
        body: {
          strokeDasharray: '0',
        },
        rect: {
          strokeDasharray: '0',
        },
      });
    }
  }

  applySelectedElementStyle() {
    if (this.selectedElement) {
      this.selectedElement.attr({
        body: {
          strokeDasharray: '5,2', // Estilo punteado
        },
        rect: {
          strokeDasharray: '5,2',
        },
      });
    }
  }

  setPaper() {
    this.paper = new joint.dia.Paper({
      el: document.getElementById(this.idGraph)!,
      model: this.graph,
      ...PAPER_CONFIG,
      interactive: () => {
        if (!this.isEditable) return { elementMove: false };
        return true;
      },
      validateConnection: (cellViewS, magnetS, cellViewT, magnetT) => {
        if (magnetS && magnetS.getAttribute('port-group') === 'top') {
          return false;
        }

        if (cellViewS === cellViewT) {
          return false;
        }

        if (magnetT && magnetT.getAttribute('port-group') === 'top') {
          if (
            cellViewT.model.attributes['data'].name === 'end' ||
            cellViewT.model.attributes['data'].name === 'assignCampaign'
          ) {
          } else {
            const inboundLinks = this.graph!.getConnectedLinks(cellViewT.model, {
              inbound: true,
            });

            if (inboundLinks.length >= 1) {
              return false;
            }
          }

          return true;
        }

        return false;
      },
    });
  }

  paperActions() {
    this.elementsTools();
    this.linksTools();
  }

  checkNode(elementSelected: any, selectedNode: NodeClass) {
    if (!this.isEditable) return;

    this.graph!.getElements().forEach((element) => {
      //DOMAIN
      let nodeElement = (this.workflow as WorkflowClass).findNode(
        element.attributes['data'].original,
      );

      if (nodeElement && nodeElement.isEmptyConfig()) {
        this.NodeValidator.showError(element);
      } else {
        this.NodeValidator.hideError(element, this.workflow);
      }
    });
    //END DOMAIN

    this.cdr.detectChanges();
  }

  updateNodes() {
    this.graph!.getElements().forEach((element) => {
      this.setCoordinates(element);
      this.checkNode(element, this.selectedNode!);
      //this.setNodeNumber(element);
      this.cdr.detectChanges();
    });
  }

  handleHighlightGoalNode() {
    if (this.selectedNode && this.selectedNode.type == NodeType.Conditional) {
      //Highlight node target from Conditional Node if exists
      const targetNode = (this.selectedNode.config as ConditionalConfig).targetNodeId;
      if (targetNode) {
        const highlightGoalNode = this.workflow.findNode(targetNode);
        if (highlightGoalNode) {
          this.highlightSelectedGoalNode(highlightGoalNode);
        }
      }
    }
  }

  elementsTools() {
    this.paper!.on('element:mouseenter', (elementView) => {
      if (this.isSelectionGoalMode) {
        elementView.$el.css('cursor', 'copy');
      }

      if (
        this.workflow.getNode(elementView.model.attributes['data'].original).type === NodeType.Start
      )
        return;

      if (this.isNodeSelector && !this.isSelectionGoalMode) {
        const removeButton = new joint.elementTools.Remove({
          useModelGeometry: true,
          y: '10%',
          x:
            elementView.model.attributes['data'].name === 'paylinkNotificationStatus'
              ? '-18%'
              : '10%',
          scale: 1.5,
        });

        const toolsView = new joint.dia.ToolsView({
          tools: [removeButton],
        });

        elementView.addTools(toolsView);
        elementView.showTools();
      }
    });

    this.paper!.on('element:mouseleave', (elementView) => {
      elementView.$el.css('cursor', 'default');
      elementView.hideTools();

      this.checkNode(elementView.model, this.selectedNode!);
    });

    this.paper!.on('cell:pointerdown', (cellView, evt, x, y) => {
      //Selection of Goal node in conditional node
      if (this.isSelectionGoalMode) {
        this.handleSelectionNodeGoal(evt, cellView);
      } else {
        this.removeHighlight();
        this.resetLastSelectedElementStyle();

        if (cellView.model instanceof joint.dia.Element) {
          this.selectedElement = cellView.model;
          //DOMAIN
          this.selectedNode = this.workflow.findNode(cellView.model.attributes['data'].original);

          this.handleHighlightGoalNode();
          //END DOMAIN
          this.checkNode(cellView.model, this.selectedNode!);
          this.applySelectedElementStyle();
        }

        this.lastSelectedElement = this.selectedElement;
      }
    });

    //TODO: MOVE TO SERVICE --> LOGIC TO UPDATE THE NODES CONDITIONAL CASES WHEN LINKS ARE CHANGED
    //LINKING ON TARGET
    this.graph!.on('change:source change:target', (link) => {
      const sourceId = link.get('source').id;
      const targetId = link.get('target').id;
      const source = link.get('source');

      if (sourceId && targetId) {
        const sourceElement = this.graph!.getCell(sourceId);
        const targetElement = this.graph!.getCell(targetId);

        //DOMAIN
        let data = sourceElement?.get('data');
        try {
          //AFTER IS LINKED
          this.paper!.on('link:connect', ($event, link) => {
            const sourceElementConnected = this.graph!.getCell(
              $event.model.attributes['source'].id,
            );
            const targetElementConnected = this.graph!.getCell(
              $event.model.attributes['target'].id,
            );

            const actualDID = sourceElementConnected.attributes['data'].original;
            const targetDID = targetElementConnected.attributes['data'].original;

            this.handleConidtionalLinking(sourceElement, targetElement, data, source);

            //EVENT IS CALLED MULTIPLE TIMES IF ARROW IS DRAGGED IN AND OUT
            (this.workflow as WorkflowClass).linkNodes(actualDID, targetDID);
          });
        } catch (error) {
          if (error instanceof NodeNotFoundError || error instanceof InvalidNodeTypeError) {
            this.toastService.showError('Error al eliminar elemento de workflow', error.message);
            console.error(error.message);
          } else {
            this.toastService.showError(
              'Error al eliminar elemento de workflow',
              (error as Error).message,
            );
            console.error(error, 'Unexpected error on remove element');
          }
        }
        //END DOMAIN
      }
    });
  }

  handleConidtionalLinking(
    sourceElement: joint.dia.Cell<joint.dia.Cell.Attributes, joint.dia.ModelSetOptions>,
    targetElement: joint.dia.Cell<joint.dia.Cell.Attributes, joint.dia.ModelSetOptions>,
    data: any,
    source: any,
  ) {
    if (
      sourceElement?.attributes['nodeType'] === 'conditional' ||
      sourceElement?.attributes['nodeType'] === 'customerCaseSegments'
    ) {
      sourceElement?.attributes?.['ports'].items.find((port: any) => {
        if (port.id === source.port) {
          if (port?.group === 'right') {
            //NODE CONDITIONAL DOMAIN LOGIC - TRUE
            this.workflow.findNode(sourceElement.attributes['data'].original).config.cases.true =
              targetElement?.attributes?.['data'].original;
            data.cases.true = targetElement?.attributes?.['data'].original;
          }
          if (port?.group === 'bottom') {
            //NODE CONDITIONAL DOMAIN LOGIC - FALSE
            this.workflow.findNode(sourceElement.attributes['data'].original).config.cases.false =
              targetElement?.attributes?.['data'].original;
            data.cases.false = targetElement?.attributes?.['data'].original;
          }
        }
        sourceElement?.set('data', data);
      });
    }
  }

  linksTools() {
    const sourceArrowheadTool = new joint.linkTools.SourceArrowhead();
    const targetArrowheadTool = new joint.linkTools.TargetArrowhead();
    const removeButton = new joint.linkTools.Remove({
      distance: 30,
    });

    const toolsView = new joint.dia.ToolsView({
      tools: [sourceArrowheadTool, targetArrowheadTool, removeButton],
    });

    if (this.isNodeSelector) {
      this.paper!.on('link:mouseenter', (linkView) => linkView.addTools(toolsView));
      this.paper!.on('link:mouseleave', (linkView) => linkView.removeTools());
      this.graph!.on('remove', (cellView, elementView) => {
        //DOMAIN - REMOVE
        const elementsGraph = this.graph!.getElements();
        //Handle remove node on highlight mode
        this.removeHighlight();
        this.isSelectionGoalMode = false;

        try {
          if (!this.destroyingComponent) {
            if (
              cellView.attributes.source &&
              cellView.attributes.type === 'standard.Link' &&
              cellView.attributes.target.id
            ) {
              //UNLINK NODES WHEN DELETE LINK
              this.unlinkOnDeleteLink(elementsGraph, cellView);
            } else if (cellView.attributes.type !== 'standard.Link') {
              //UNLINK WHEN CELLVIEW IS NODE
              this.unlinkOnDeleteNode(cellView);
            }
          }
        } catch (error) {
          if (error instanceof NodeNotFoundError || error instanceof InvalidNodeTypeError) {
            this.toastService.showError('Error removing', error.message);
            console.error(error.message);
          } else {
            console.error(error, 'Unexpected error');
          }
        }
        //END DOMAIN
      });
    }
  }

  unlinkOnDeleteLink(
    elementsGraph: joint.dia.Element<joint.dia.Element.Attributes, joint.dia.ModelSetOptions>[],
    cellView: any,
  ) {
    const sourceElementLink = elementsGraph.find((el) => el.id === cellView.attributes.source.id);
    const targetElementLink = elementsGraph.find((el) => el.id === cellView.attributes.target.id);
    let parentDNode = sourceElementLink?.attributes['data'].original;
    let childDNode = targetElementLink?.attributes['data'].original;

    (this.workflow as WorkflowClass).unlinkNodes(parentDNode, childDNode);
    this.selectedElement = undefined;
    this.selectedNode = undefined;
  }

  unlinkOnDeleteNode(cellView: any) {
    this.graph?.removeCells(cellView);

    if (
      (this.selectedNode && this.selectedNode.id === cellView.attributes['data'].original) ||
      (this.selectedNode && !cellView.attributes['data'].original)
    ) {
      (this.workflow as WorkflowClass).removeNode(this.selectedNode.id);
    } else {
      (this.workflow as WorkflowClass).removeNode(cellView.attributes['data'].original);
    }
  }

  svgPanZoom() {
    this.panZoom = svgPanZoom(this.paper!.svg, ZOOM_CONFIG(this.paper!));
    this.graph!.getElements().length < 5 ? this.panZoom.zoom(0.4) : this.panZoom.zoom(0.8);
    this.paper!.on('blank:pointerdown', () => {
      document.body.style.cursor = 'auto';
      this.panZoom.enablePan();
    });

    this.paper!.on('blank:pointerup', () => {
      document.body.style.cursor = 'auto';
      this.panZoom.disablePan();
    });

    const controls = document.getElementById('svg-pan-zoom-controls') as HTMLElement;
    controls.setAttribute('style', 'display: none !important');
  }

  handleNodeChange({ id, newAttributes }: { id: string; newAttributes: any }) {
    let cell = this.graph!.getCell(id);
    if (cell) {
      this.setNodeData(cell, newAttributes);
      this.updateNodes();
      this.setCoordinates(cell);
      this.setNewNodes();
      this.cdr.detectChanges();
    }
  }

  setCoordinates(cell: any) {
    let diagram = {
      coordinates: {
        x: Math.round(cell.get('position').x),
        y: Math.round(cell.get('position').y),
      },
    };
    cell.set('diagram', diagram);
  }

  setNodeData(cell: any, newAttributes: any) {
    let data = cell.get('data');
    let cleanNewAttributes = helper.cleanAttributes(newAttributes);
    data = { ...data, ...cleanNewAttributes };
    data = helper.verifyAndCleanParameters(data);
    cell.set('data', data);
  }

  setNewNodes() {
    this.nodesReady.emit(helper.transformData(this.graph!.toJSON()));
  }

  ngOnDestroy(): void {
    this.destroyingComponent = true;
    this.graph!.clear();
    this.paper!.remove();
    this.destroyingComponent = false;
  }

  highlightGoalNodes($event: any) {
    //Called method from event thrown on node-inspector by conditional node
    const parentNodesWithCopyIds = (
      this.workflow as WorkflowClass
    ).getParentNodesWithCopyIdsInBranch(this.selectedNode!);

    this.graph!.getElements().forEach((element) => {
      const nodeId = element.attributes['data'].original;
      const node = (this.workflow as WorkflowClass).findNode(nodeId);

      if (node && parentNodesWithCopyIds.includes(node)) {
        this.isSelectionGoalMode = true;

        const elementView = this.paper!.findViewByModel(element);
        joint.highlighters.mask.add(elementView, { selector: 'root' }, 'my-element-highlight', {
          deep: true,
          attrs: {
            stroke: '#ff851b',
            'stroke-width': 5,
            [`stroke-dasharray`]: '5,2',
          },
        });
      }
    });
  }

  highlightSelectedGoalNode(node: NodeClass) {
    const element = this.graph!.getElements().find(
      (element) => element.attributes['data'].original === node.id,
    );

    if (element) {
      const elementView = this.paper!.findViewByModel(element);
      if (elementView instanceof joint.dia.ElementView) {
        joint.highlighters.mask.add(elementView, { selector: 'root' }, 'my-element-highlight', {
          deep: true,
          attrs: {
            stroke: '#ff851b',
            'stroke-width': 5,
          },
        });
      }
    }
  }

  removeHighlight() {
    joint.highlighters.mask.removeAll(this.paper!);
  }

  zoomToNode(position: { x: number; y: number }) {
    if (this.panZoom) {
      const { x, y } = position;

      const container = this.panZoom.getSizes();
      const zoomLevel = 2;

      const pan = {
        x: -x * zoomLevel + container.width / 2,
        y: -y * zoomLevel + container.height / 2,
      };

      this.panZoom.zoom(zoomLevel);
      this.panZoom.pan(pan);
    }
  }

  handleSelectionNodeGoal(evt: any, cellView: any) {
    const parentNodesWithCopyIds = (
      this.workflow as WorkflowClass
    ).getParentNodesWithCopyIdsInBranch(this.selectedNode!);

    const clickedNode = this.workflow.findNode(cellView.model.attributes['data'].original);

    const isAvailableToClick = parentNodesWithCopyIds.some((node) => node.id === clickedNode.id);

    if (hascopyIds(clickedNode.config) && isAvailableToClick) {
      evt.stopPropagation();
      this.selectedGoalNode = this.workflow.findNode(cellView.model.attributes['data'].original);
      this.selectedNode = this.workflow.findNode(
        this.lastSelectedElement?.attributes['data'].original,
      );

      if (this.selectedGoalNode) {
        //Add target node to conditional node
        (this.selectedNode?.config as ConditionalConfig).targetNodeId = this.selectedGoalNode?.id;
      }

      this.removeHighlight();
      this.highlightSelectedGoalNode(this.selectedGoalNode!);

      this.isSelectionGoalMode = false;
    } else {
      this.toastService.showInfo('Nodo no indicado', 'Debe seleccionar un nodo adecuado');
    }
  }
}
