import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meter-group',
  template: `
    <div class="meter-group">
      <div class="meter-bar">
        <div
          *ngFor="let meter of meters"
          class="meter-segment"
          [ngStyle]="{
            'width.%': (meter.value / totalValue) * 100,
            'background-color': meter.color
          }"
        ></div>
      </div>
      <div class="meter-labels">
        <div *ngFor="let meter of meters" class="meter-label">
          <span class="p-1" [ngStyle]="{ color: meter.color }">●</span>
          {{ meter.label }} ({{ (meter.value / totalValue) * 100 | number: '1.0-2' }}%)
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .meter-group {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .meter-bar {
        display: flex;
        height: 12px;
        background-color: #e5e7eb;
        border-radius: 10px;
        overflow: hidden;
      }

      .meter-segment {
        height: 100%;
      }

      .meter-labels {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
      }

      .meter-label {
        font-size: 10px;
        color: #333333;
      }
    `,
  ],
})
export class MeterGroupComponent {
  @Input() meters: Array<{ label: string; value: number; color?: string }> = [];

  get totalValue(): number {
    return this.meters.reduce((sum, meter) => sum + meter.value, 0);
  }
}
