import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import {
  AssaignCampaignConfig,
  ConditionalConfig,
  ConditionalSegmentConfig,
  EmailCertifiedConfig,
  EmailConfig,
  MakeOutBoundCallConfig,
  OfferPartialPaymentConfig,
  OfferPaymentAgreementConfig,
  SMSCertifiedConfig,
  SMSConfig,
  SuperEmailCertifiedConfig,
  SuperEmailConfig,
  SuperSMSCertifiedConfig,
  SuperSMSConfig,
  WaitDayOfMonthConfig,
  WaitDaysConfig,
  WaitDaysForDueDateConfig,
  WaitDaysOfWeekConfig,
} from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { WorkflowClass } from '@modules/campaigns/Domain/WorkflowClass';
import { NodeFactory } from '@modules/campaigns/Domain/Factories/NodeFactory';
import { constrainPoint } from '@fullcalendar/core/internal';
import { MakeOutBoundCallNode } from '@modules/campaigns/Domain/Nodes/MakeOutBoundCall';

export class ParseInfoFormToClassService {
  static ParseInfoFormToClass(data: any, workflow: WorkflowClass, node: NodeClass): void {
    if (!node) {
      throw new Error(`No node provided`);
    }

    if (node) {
      switch (NodeFactory.mapStringToNodeType(node.type)) {
        case NodeType.WaitDays:
          const updatedAttributes = {
            partDay: (node.config as WaitDaysConfig).partDay,
            waitDays: (node.config as WaitDaysConfig).waitDays,
            ...data,
          };
          (node.config as WaitDaysConfig).partDay = updatedAttributes.partDay;
          (node.config as WaitDaysConfig).waitDays =
            updatedAttributes.waitDays !== '' ? updatedAttributes.waitDays : 0;
          break;
        case NodeType.WaitDayOfWeek:
          const updatedAttributesWeek = {
            partDay: (node.config as WaitDaysOfWeekConfig).partDay,
            diaDeLaSemana: (node.config as WaitDaysOfWeekConfig).dayOfWeek,
            ...data,
          };
          (node.config as WaitDaysOfWeekConfig).partDay = updatedAttributesWeek.partDay;
          (node.config as WaitDaysOfWeekConfig).dayOfWeek = updatedAttributesWeek.dayOfWeek;
          break;
        case NodeType.WaitDaysForDueDate:
          const updatedAttributesDueDate = {
            partDay: (node.config as WaitDaysForDueDateConfig).partDay,
            waitDays: (node.config as WaitDaysForDueDateConfig).waitDays,
            antesODespues: (node.config as WaitDaysForDueDateConfig).antesODespues,
            ...data,
          };
          (node.config as WaitDaysForDueDateConfig).partDay = updatedAttributesDueDate.partDay;
          (node.config as WaitDaysForDueDateConfig).waitDays = updatedAttributesDueDate.waitDays;
          (node.config as WaitDaysForDueDateConfig).antesODespues =
            updatedAttributesDueDate.antesODespues;
          break;
        case NodeType.WaitDayOfMonth:
          const updatedAttributesMonth = {
            partDay: (node.config as WaitDaysConfig).partDay,
            dayOfMonth: (node.config as WaitDayOfMonthConfig).dayOfMonth,
            ...data,
          };
          (node.config as WaitDayOfMonthConfig).partDay = updatedAttributesMonth.partDay;
          (node.config as WaitDayOfMonthConfig).dayOfMonth = updatedAttributesMonth.dayOfMonth;
          break;
        case NodeType.SendSMS:
          (node.config as SMSConfig).copyIds = [];
          (node.config as SMSConfig).copyIds = data.copyIds ? [data.copyIds] : [];
          (node.config as SMSConfig).sendToAllContacts = data.sendToAllContacts;
          break;
        case NodeType.SendCertifiedSMS:
          (node.config as SMSCertifiedConfig).copyIds = [];
          (node.config as SMSCertifiedConfig).sendToAllContacts = data.sendToAllContacts;
          (node.config as SMSCertifiedConfig).copyIds = data.copyIds ? [data.copyIds] : [];
          break;
        case NodeType.SendEmail:
          (node.config as EmailConfig).copyIds = [];
          (node.config as EmailConfig).sendToAllContacts = data.sendToAllContacts;
          (node.config as EmailConfig).copyIds = data.copyIds ? [data.copyIds] : [];
          break;
        case NodeType.SendCertifiedEmail:
          (node.config as EmailCertifiedConfig).copyIds = [];
          (node.config as EmailCertifiedConfig).sendToAllContacts = data.sendToAllContacts;
          (node.config as EmailCertifiedConfig).copyIds = data.copyIds ? [data.copyIds] : [];
          break;
        case NodeType.SuperSendSMS:
          (node.config as SuperSMSConfig).copyIds = data.copyIds;
          (node.config as SuperSMSConfig).sendToAllContacts = data.sendToAllContacts;

          break;
        case NodeType.SuperSendCertifiedSMS:
          (node.config as SuperSMSCertifiedConfig).copyIds = data.copyIds;
          (node.config as SuperSMSCertifiedConfig).sendToAllContacts = data.sendToAllContacts;

          break;
        case NodeType.SuperSendEmail:
          (node.config as SuperEmailConfig).copyIds = data.copyIds;
          (node.config as SuperEmailConfig).sendToAllContacts = data.sendToAllContacts;

          break;
        case NodeType.SuperSendCertifiedEmail:
          (node.config as SuperEmailCertifiedConfig).copyIds = data.copyIds;
          (node.config as SuperEmailCertifiedConfig).sendToAllContacts = data.sendToAllContacts;
          break;
        case NodeType.AssignCampaign:
          (node.config as AssaignCampaignConfig).targetCampaignId = data.campañaId;
          break;
        case NodeType.MakeOutBoundCall:
          (node.config as MakeOutBoundCallConfig).voiceId = data.voiceId;
          (node.config as MakeOutBoundCallConfig).copyId = data.copyId;
          break;
        case NodeType.OfferPaymentAgreement:
          (node.config as OfferPaymentAgreementConfig).expirationDays = data.expirationDays;
          (node.config as OfferPaymentAgreementConfig).numberOfInstalments =
            data.numberOfInstalments;
          break;
        case NodeType.Conditional:
          const updatedAttributesConditional = {
            targetNodeID: (node.config as ConditionalConfig).targetNodeId,
            status: (node.config as ConditionalConfig).statuses,
            ...data,
          };
          (node.config as ConditionalConfig).targetNodeId =
            updatedAttributesConditional.targetNodeID;
          (node.config as ConditionalConfig).statuses = updatedAttributesConditional.status;
          break;
        case NodeType.ConditionalSegments:
          const segmentIds = (node.config as ConditionalSegmentConfig).segmentIds;
          const updatedSegmentIds = Array.isArray(data.segmentIds)
            ? data.segmentIds
            : [data.segmentIds];

          (node.config as ConditionalSegmentConfig).segmentIds = [];

          (node.config as ConditionalSegmentConfig).segmentIds = updatedSegmentIds;
          break;
        case NodeType.OfferPartialPayment:
          const updatedAttributesOfferPartialPayment = {
            expirationDays: (node.config as OfferPartialPaymentConfig).expirationDays,
            minimumPercentageAmount: (node.config as OfferPartialPaymentConfig)
              .minimumPercentageAmount,
            amount: (node.config as OfferPartialPaymentConfig).minimumAmount.amount,
            ...data,
          };
          (node.config as OfferPartialPaymentConfig).expirationDays =
            updatedAttributesOfferPartialPayment.expirationDays;
          (node.config as OfferPartialPaymentConfig).minimumPercentageAmount =
            updatedAttributesOfferPartialPayment.minimumPercentageAmount;
          (node.config as OfferPartialPaymentConfig).minimumAmount.amount =
            updatedAttributesOfferPartialPayment.amount;

          break;
        default:
          throw new Error('Invalid type of node, not found');
      }
    }

    workflow.updateNode(node);
  }
}
