import { Observable, Subscription, forkJoin } from 'rxjs';
import { Component, Input, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, ResetService } from '@services/ui';
import { DialogService } from 'primeng/dynamicdialog';
import { TYPE_NOTIFICATION, STATUS } from '@models/global-entities.model';
import { CustomerService } from '@modules/customers/customer.service';
import { SendReminderModalComponent } from './components/send-reminder-modal/send-reminder-modal.component';

@Component({
  selector: 'app-send-reminder',
  templateUrl: './send-reminder.component.html',
})
export class SendReminderComponent {
  @Input() resetEnabled: boolean = true;
  @Input() reminderData: {
    customerCase: any;
  } = {} as { customerCase: any };

  private subscription: Subscription = new Subscription();

  customerService = inject(CustomerService);
  dModal = inject(DialogService);
  toastService = inject(ToastService);
  translateService = inject(TranslateService);
  resetService = inject(ResetService);

  initSender() {
    const ref = this.dModal.open(SendReminderModalComponent, {
      data: this.reminderData,
      width: '50vw',
      height: '55vh',
      closeOnEscape: true,
      draggable: true,
      resizable: false,
    });

    const closeSubscription = ref.onClose.subscribe((data) => {
      if (data) {
        this.handleNotificationActions(data);
      }
    });
    this.subscription.add(closeSubscription);
  }

  private handleNotificationActions(data: any): void {
    const actions = this.prepareNotificationActions(data);
    forkJoin(actions).subscribe((results) => {
      this.processNotificationResults(results, data);
      if (this.resetEnabled) this.resetService.reset();
    });
  }

  private prepareNotificationActions(data: any): Observable<any>[] {
    const actions: Observable<any>[] = [];
    if (data.email) {
      actions.push(
        this.customerService.sendEmailPaylinkNotificationUsage(
          data.customerCase.id || data.customerCase.customerCaseId,
          TYPE_NOTIFICATION.REMINDER,
        ),
      );
    }
    if (data.sms) {
      actions.push(
        this.customerService.sendSmsPaylinkNotificationUsage(
          data.customerCase.id || data.customerCase.customerCaseId,
          TYPE_NOTIFICATION.REMINDER,
        ),
      );
    }
    return actions;
  }

  private processNotificationResults(results: any[], data: any): void {
    results.forEach((result) => {
      if (this.isSuccessfulEmail(result)) {
        this.handleEmailResponse(data);
        return;
      }
      if (this.isSuccessfulSMS(result)) {
        this.handleSmsResponse(data);
        return;
      }

      if (
        !this.isSuccessfulEmail(result) &&
        !this.isSuccessfulSMS(result) &&
        result.error[0].message
      ) {
        this.handleErrors(result.error[0].message);
      }
    });
  }

  private isSuccessfulEmail(result: any): boolean {
    return result.sendEmailPaylinkNotificationUsage?.status === 'ok';
  }

  private isSuccessfulSMS(result: any): boolean {
    return result.sendSmsPaylinkNotificationUsage?.status === 'ok';
  }

  private handleEmailResponse(data: any): void {
    this.toastService.showSuccess(
      this.translateService.instant('DATA.COMMON.TOAST.SUCCESS'),
      this.translateService.instant('DATA.COMMON.TOAST.SUCCESS_EMAIL', data),
    );
  }

  private handleSmsResponse(data: any): void {
    this.toastService.showSuccess(
      this.translateService.instant('DATA.COMMON.TOAST.SUCCESS'),
      this.translateService.instant('DATA.COMMON.TOAST.SUCCESS_SMS', data),
    );
  }

  private handleErrors(error: string): void {
    this.toastService.showError(this.translateService.instant('DATA.COMMON.TOAST.ERROR'), error);
  }

  showReminder(): boolean {
    if (this.reminderData.customerCase.totalAmount === 0) {
      return false;
    }

    switch (this.reminderData.customerCase.status) {
      case STATUS.OPEN:
        return true;
      case STATUS.SILENCED:
      case STATUS.WORKED:
        return true;
      // return !!this.reminderData.customerCase.operations.find(
      //   (op: any) => op.status === STATUS.PENDING || op.status === STATUS.IN_ALLEGATIONS,
      // );
      default:
        return false;
    }
  }
}
