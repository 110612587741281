import { gql } from 'apollo-angular';

export const GET_COPY = gql`
  query ($id: ID!) {
    copy(id: $id) {
      id
      tenantId
      type
      version
      alias
      templateId
      name
      isReminder
      isPaymentAgreement
      blocks {
        translations {
          locale
          text
        }
        type
        alias
        order
        maxCharacters
      }
    }
  }
`;

export const GET_COPIES_LIST = gql`
  query ($tenantId: ID!) {
    copies(tenantId: $tenantId) {
      id
      tenantId
      type
      version
      alias
      templateId
      name
      isReminder
      isPaymentAgreement
      blocks {
        translations {
          locale
          text
          __typename
        }
        type
        alias
        order
        maxCharacters
        __typename
      }
      __typename
    }
  }
`;

export const DELETE_COPY = gql`
  mutation ($id: ID!) {
    deleteCopy(id: $id) {
      id
    }
  }
`;

export const UPDATE_COPY = gql`
  mutation ($id: ID!, $input: ReleaseCopyInput!) {
    releaseCopy(id: $id, input: $input) {
      id
      tenantId
      type
      version
      alias
      templateId
      name
      isReminder
      isPaymentAgreement
      blocks {
        translations {
          locale
          text
        }
        type
        alias
        order
        maxCharacters
      }
    }
  }
`;

export const GET_CERTIFIED_NOTIFICATION_CONFIG = gql`
  query ($tenantId: ID!) {
    certifiedNotificationsSettings(tenantId: $tenantId) {
      id
      tenantId
      smsActive
      emailActive
      smsLimits {
        limit
        __typename
      }
      __typename
    }
  }
`;

export const GENERATE_COPY = gql`
  mutation ($input: AutoGenerateCopyInput!) {
    autoGenerateCopy(input: $input) {
      id
      blocks
    }
  }
`;

export const CREATE_COPY = gql`
  mutation ($input: CreateCopyInput!) {
    createCopy(input: $input) {
      id
      tenantId
      type
      version
      alias
      templateId
      name
      isReminder
      isPaymentAgreement
      blocks {
        translations {
          locale
          text
        }
        type
        alias
        order
        maxCharacters
      }
    }
  }
`;
