<div class="layout-topbar">
   <div class="flex gap-2">
      <button #menubutton type="button" class="topbar-menubutton p-link p-trigger mr-2" (click)="onMenuButtonClick()">
         <i class="pi pi-chevron-right"></i>
      </button>
      <p-menubar [model]="itemsStart"> </p-menubar>
   </div>

   <div class="topbar-end flex gap-3 align-items-center">
      <p-chip
         [label]="selectedTimezone"
         icon="pi pi-hourglass"
         [pTooltip]="
            ' Ajusta la zona horaria para ver las horas exactas de pagos en otros países. Por ejemplo, si estás en España, puedes ver a qué hora se realizaron pagos en México o Perú.'
         "
         [tooltipPosition]="'bottom'"
         (click)="showTimezone()"
         style="cursor: pointer"
      ></p-chip>
      <p-button
         *ngIf="isAllow([PERMISSION_TYPE.FULL_ACCESS])"
         [icon]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-server'"
         [routerLink]="['/tenants']"
         [queryParamsHandling]="'preserve'"
         [pTooltip]="!loading ? tenantName : ''"
         [tooltipPosition]="'bottom'"
         [label]="loading ? 'Cargando...' : (tenantName | ellipsis: 8)"
         styleClass="p-button-outlined p-button-secondary"
      ></p-button>
      <p-button
         *ngIf="isAllow([PERMISSION_TYPE.FULL_ACCESS, PERMISSION_TYPE.ACTIVITY])"
         [icon]="'pi pi-arrows-v'"
         [label]="'Actividades'"
         (click)="onActivityButtonClick()"
         styleClass="p-button-outlined p-button-secondary"
      ></p-button>
      <button type="button" class="p-link" (click)="onProfileButtonClick()">
         <p-avatar
            [label]="letters(user.name)"
            styleClass="w-3rem h-3rem border-circle"
            size="xlarge"
            [style]="{ 'background-color': '#D3D3D3', color: 'black' }"
            shape="circle"
         ></p-avatar>
      </button>
   </div>
</div>
