import { Component, HostListener, Input } from '@angular/core';
import { NodesValidator } from '../../helpers/nodesValidators';

@Component({
  selector: 'app-graph-controllers',
  templateUrl: './graph-controllers.component.html',
  styleUrls: ['./graph-controllers.component.scss'],
})
export class GraphControllersComponent {
  @Input() panZoom!: SvgPanZoom.Instance;
  @Input() paper!: joint.dia.Paper;
  @Input() graph!: joint.dia.Graph;
  @Input() isEditable!: boolean;

  isZoomable = false;
  NodeValidator = new NodesValidator();

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.zoom();
      this.isZoomable = true;
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.block();
      this.isZoomable = false;
    }
  }

  center() {
    this.panZoom.center();
    this.panZoom.zoom(0.4);
  }

  changeZoomManual() {
    this.isZoomable = !this.isZoomable;

    if (this.isZoomable) {
      this.zoom();
    } else {
      this.block();
    }
  }

  block() {
    this.panZoom.disableZoom();
    this.panZoom.disableDblClickZoom();
    this.panZoom.disableMouseWheelZoom();
  }

  zoom() {
    this.panZoom.enableZoom();
    this.panZoom.enableDblClickZoom();
    this.panZoom.enableMouseWheelZoom();
  }

  download() {
    const svgData = this.paper.svg.cloneNode(true);
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgData);
    const blob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'paymefy-campaign.svg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }
}
