import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import {
  CREATE_COPY,
  DELETE_COPY,
  GENERATE_COPY,
  GET_COPIES_LIST,
  GET_COPY,
  UPDATE_COPY,
} from '../graphql/copies.GraphQl';
import { Observable, map, takeWhile } from 'rxjs';
import { CopyBlockTranslationModel, CopyBlockModel, CopyModel } from '@models/copies/copies.model';
import { ulid } from 'ulid';
import { AutoCopyInput } from '@models/copies/copiesIA.model';
import { certifiedEmailOption, certifiedSmsOption } from '@shared/helpers/variables';
import { CurrentUserService } from '@core/services/currentUser.service';
import { PermissionType } from '@admin/modules/users/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class CopiesService {
  route = inject(ActivatedRoute);
  apollo = inject(Apollo);
  currentUserService = inject(CurrentUserService);

  searchCopies(): Observable<any> {
    return this.apollo
      .query({
        query: GET_COPIES_LIST,
        variables: {
          tenantId: this.route.snapshot.queryParams['tenant'],
        },
      })
      .pipe(map((res: any) => res.data.copies));
  }

  getCopy(id: string): Observable<any> {
    return this.apollo
      .query({
        query: GET_COPY,
        variables: {
          id,
        },
      })
      .pipe(map((res: any) => res));
  }

  deleteCopy(id: string): Observable<any> {
    return this.apollo
      .mutate({
        mutation: DELETE_COPY,
        variables: {
          id,
        },
      })
      .pipe(map((res: any) => res.data.deleteCopy));
  }

  updateCopy(copy: CopyModel): Promise<any> {
    const emitCopy = { ...copy };
    this.cleanCopyAttrs(emitCopy);
    return new Promise<any>((resolve) => {
      this.apollo
        .mutate({
          mutation: UPDATE_COPY,
          variables: {
            id: copy.id,
            input: emitCopy,
          },
        })
        .subscribe((res: any) => resolve(res));
    });
  }

  generateCopy(input: AutoCopyInput): Promise<any> {
    const {
      channel,
      languages,
      sector,
      numberOfCharacters,
      treatment,
      tone,
      target,
      placeholders,
    } = input;
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .mutate({
          mutation: GENERATE_COPY,
          variables: {
            input: {
              id: ulid(),
              channel,
              languages,
              sector,
              numberOfCharacters,
              treatment,
              tone,
              target,
              placeholders,
            },
          },
        })
        .pipe(takeWhile((res: any) => !res.blocks))
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res.errors[0].message);
          } else {
            resolve(res.data.autoGenerateCopy);
          }
        });
    });
  }

  createCopy(input: any): Promise<any> {
    const { type, alias, templateId, name, blocks } = input;
    return new Promise<any>((resolve) => {
      this.apollo
        .mutate({
          mutation: CREATE_COPY,
          variables: {
            input: {
              id: ulid(),
              tenantId: this.route.snapshot.queryParams['tenant'],
              type,
              alias,
              templateId,
              name,
              isReminder: false,
              isPaymentAgreement: false,
              blocks,
            },
          },
        })
        .subscribe((res: any) => resolve(res));
    });
  }

  generateCopyBlocks(
    inputData: any,
    type: string,
    numberOfCharacters: number | null,
  ): CopyBlockModel[] {
    let copyBlocks: CopyBlockModel[] = [];

    let fields =
      type === 'sms' || type === 'certified_sms'
        ? ['text']
        : ['subject', 'preHeader', 'text', 'button'];

    fields.forEach((field, fieldIndex) => {
      const check = () => {
        if (type === 'sms') {
          return 'smsText';
        }

        if (type === 'certified_sms') {
          return 'certifiedSmsText';
        }

        return field;
      };

      let copyBlock: CopyBlockModel = {
        translations: [],
        type: check(),
        alias: check(),
        order: fieldIndex + 1,
        maxCharacters: numberOfCharacters || null,
      };

      inputData.forEach((data: any) => {
        copyBlock.translations.push({
          locale: data.value,
          text: data.content[field],
        });
      });

      copyBlocks.push(copyBlock);
    });

    return copyBlocks;
  }

  //UTILS
  getCoincidencies(
    copies: CopyModel[],
    namePartials?: string[],
    locales?: string[],
    types?: string[],
  ) {
    if (!copies) {
      return [];
    }

    return copies.filter(
      (copy) =>
        (!namePartials ||
          namePartials.length === 0 ||
          namePartials.some(
            (namePartial) =>
              !namePartial ||
              (copy.name && copy.name.toLowerCase().includes(namePartial.toLowerCase())),
          )) &&
        (!types || types.some((type) => copy.type === type)) &&
        (!locales ||
          locales.some(
            (locale) =>
              copy.blocks &&
              copy.blocks.some(
                (block: CopyBlockModel) =>
                  block.translations &&
                  block.translations.some(
                    (translation: CopyBlockTranslationModel) => translation.locale === locale,
                  ),
              ),
          )),
    );
  }

  getLanguages(blocks: CopyBlockModel[]) {
    return Array.from(
      new Set(
        blocks.flatMap((block: CopyBlockModel) =>
          block.translations.map((translation: CopyBlockTranslationModel) => translation.locale),
        ),
      ),
    ).join(', ');
  }

  getName(
    name: keyof {
      email: string;
      sms: string;
      certified_sms: string;
      certified_email: string;
    },
  ) {
    const map = {
      email: 'Email',
      sms: 'SMS',
      certified_sms: 'SMS certificado',
      certified_email: 'Email certificado',
    };

    return map[name];
  }

  private cleanCopyAttrs(copy: any) {
    delete copy.id;
    delete copy.tenantId;
    delete copy.type;
    delete copy.version;
    delete copy['__typename'];
    copy.blocks.forEach((block: any) => {
      delete block['__typename'];
      block.translations.forEach((translation: any) => {
        delete translation['__typename'];
      });
    });
  }

  checkArray(array: any) {
    for (let i = 0; i < array.length; i++) {
      let obj = array[i];

      for (let key in obj) {
        if (obj['channel'] === 'email' && key === 'pre-header') continue;

        if (obj['channel'] === 'sms' && key === 'text' && (!obj[key] || obj[key] === '')) {
          return false;
        }

        if (!obj[key] || obj[key] === '') {
          return false;
        }
      }
    }
    return true;
  }

  setCertifiedConfig(certifiedConfig: any, formConfig: any) {
    const channelConfig = formConfig.find((item: any) => item.key === 'channel');

    if (!channelConfig) return;

    channelConfig.options = channelConfig.options.filter(
      (option: any) =>
        option.value !== certifiedEmailOption.value && option.value !== certifiedSmsOption.value,
    );

    if (certifiedConfig?.emailActive) {
      const existingEmailOption = channelConfig.options.find(
        (option: any) => option.value === certifiedEmailOption.value,
      );
      if (!existingEmailOption) {
        channelConfig.options.push(certifiedEmailOption);
      }
    }

    if (certifiedConfig?.smsActive) {
      const existingSmsOption = channelConfig.options.find(
        (option: any) => option.value === certifiedSmsOption.value,
      );
      if (!existingSmsOption) {
        channelConfig.options.push(certifiedSmsOption);
      }
    }
  }

  setNoC(certifiedConfig: any, formConfig: any) {
    let nOc = formConfig.find((item: any) => item.key === 'numberOfCharacters')!.options;

    if (nOc.length > 0) {
      nOc = [];
    }

    certifiedConfig?.smsLimits.map((item: any) =>
      nOc.push({
        label: item.limit,
        value: item.limit,
      }),
    );
  }

  checkValues(items: any, channel: string, numberOfCharacters: number | null) {
    let result = true;
    items.forEach((item: any) => {
      if (channel === 'certified_sms') {
        if (
          !item.content ||
          !item.content.text ||
          (numberOfCharacters && item.content.text.length > numberOfCharacters)
        ) {
          result = false;
          return;
        }
      } else if (channel === 'sms') {
        if (!item.content || !item.content.text) {
          result = false;
          return;
        }
      } else if (channel === 'email' || channel === 'certified_email') {
        Object.keys(item.content).forEach((key) => {
          if (key !== 'preHeader' && (item.content[key] === '' || item.content[key] === null)) {
            result = false;
            return;
          }
        });
      } else {
        result = false;
      }
    });
    return result;
  }

  checkValue(item: any, channel: string, numberOfCharacters: number | null) {
    let result = true;
    if (channel === 'certified_sms') {
      if (
        !item.content ||
        !item.content.text ||
        (numberOfCharacters && item.content.text.length > numberOfCharacters)
      ) {
        result = false;
      }
    } else if (channel === 'sms') {
      if (!item.content || !item.content.text) {
        result = false;
      }
    } else if (channel === 'email' || channel === 'certified_email') {
      if (item.content) {
        Object.keys(item.content).forEach((key) => {
          if (key !== 'preHeader' && (item.content[key] === '' || item.content[key] === null)) {
            result = false;
          }
        });
      } else {
        result = false;
      }
    } else {
      result = false;
    }
    return result;
  }

  canWrite() {
    return this.currentUserService.isPermissionAuthorized([
      PermissionType.FULL_ACCESS,
      PermissionType.COPY_WRITE,
    ]);
  }

  canRead() {
    return this.currentUserService.isPermissionAuthorized([
      PermissionType.FULL_ACCESS,
      PermissionType.COPY_READ,
    ]);
  }
}
