<img
    *ngIf="countryCode === 'cat'"
    [src]="'/assets/media/flags/cat.svg'"
    style="width: 20px; height: 30px; margin-right: 8px"
/>

<img
    *ngIf="countryCode === 'ca_ES_VALENCIA'"
    [src]="'/assets/media/flags/cv.svg'"
    style="width: 20px; height: 30px; margin-right: 8px"
/>

<img
    *ngIf="countryCode !== 'cat' && countryCode !== 'ca_ES_VALENCIA'"
    src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
    [class]="'flag flag-' + countryCode"
    style="width: 20px; margin-right: 8px"
/>
