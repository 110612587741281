import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { LocationStrategy, NgStyle, PathLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { GraphQLModule } from './core/services/api/graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { TenantGuard } from './core/guards/tenant.guard';
import { RouteLoaderService } from './core/services/ui/routerLoader.service';
import { ToastModule } from 'primeng/toast';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { TRANSLATIONS } from './core/services/i18n/vocabs';
import { Observable, of } from 'rxjs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Router } from '@angular/router';

export class CustomLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(TRANSLATIONS[lang]);
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AppLayoutModule,
    GraphQLModule,
    ToastModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
      },
    }),
    InfiniteScrollModule,
    NgStyle,
  ],
  providers: [
    TenantGuard,
    RouteLoaderService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule],
})
export class AppModule {}
