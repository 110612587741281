import { Component, EventEmitter, Input, Output } from '@angular/core';
import { draggableMenu, draggables } from '../../config/constants';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CreateNewNodesService } from '../../services/create-new-nodes.service';
import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeFactory } from '@modules/campaigns/Domain/Factories/NodeFactory';
import { WorkflowClass } from '@modules/campaigns/Domain/WorkflowClass';

@Component({
  selector: 'app-node-drag-drop',
  templateUrl: './node-drag-drop.component.html',
  styleUrls: ['./node-drag-drop.component.scss'],
})
export class NodeDragDropComponent {
  @Input() graph: any;
  @Input() workflow: WorkflowClass | undefined;
  @Output() nodeDrag = new EventEmitter<any>();
  @Output() nodeDDrag = new EventEmitter<NodeClass>();
  @Output() nodeDragDomain = new EventEmitter<NodeClass>();
  draggables: draggableMenu = draggables;

  constructor(private createNewNodes: CreateNewNodesService) {}

  drop(event: CdkDragDrop<string[]>) {
    const type = event.item.element.nativeElement.id;
    if (!type) return;
    const localPoint = this.graph.paper!.clientToLocalPoint(event.dropPoint.x, event.dropPoint.y);
    let newNode = this.createNewNodes.createNode(type, localPoint.x, localPoint.y);

    //DOMAIN
    const newDNodeID = newNode?.attributes?.['data'].original;
    const nodeType = NodeFactory.mapStringToNodeType(type);
    if (nodeType && newDNodeID) {
      let newDNode = NodeFactory.createNode(
        nodeType,
        localPoint.x,
        localPoint.y,
        newDNodeID.toString(),
      );
      if (newDNode && this.workflow) {
        newDNode.initNodeConfig();
        this.workflow.addNode(newDNode);
      } else {
        //ERROR
        //TODO: HANDLE ERROR
        console.log('ERROR ADD NODE NOT EXSIT');
      }
    }
    //END DOMAIN

    if (newNode) {
      this.graph.graph!.addCell(newNode);
      this.graph.selectedElement = newNode;
      this.dragNode(newNode);
    }
  }

  dragNode(element: any) {
    this.nodeDrag.emit(element);
  }
}
